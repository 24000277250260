import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/logout',
        name: 'logout',
        component: Unauthorized
    },
    {
        path: "/User/Home",
        name: "home",
        component: () => import('../views/Expense/Dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' }
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/logout',
        name: 'logout',
        component: Unauthorized
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },  
    {
        path: "/Expenses/Index",
        name: "Expense",          
        component: () => import('../views/Expense/Expense/ExpenseList.vue'),
        meta: { title: 'Expenses',  
        checkPrivilege: false,      
        breadcrumb: [
            { name: "Expenses" }           
        ] } 
    },
    {
        path: "/Expenses/Add",
        name: "ManageExpense",        
        component: () => import('../views/Expense/Expense/ManageCustomLayout.vue'),
        meta: { title: 'Manage Expense',
        userguide:'User_Guide_Expense',
        breadcrumb: [
            {name:"Expenses", link:"/Expenses/Index"},
            {name: "Manage"}
        ] }         
    },
    {
        path: "/Expenses/Add/:Id",
        name: "ManageExpense",        
        component: () => import('../views/Expense/Expense/ManageCustomLayout.vue'),
        meta: { title: 'Manage Expense',
        userguide:'User_Guide_Expense',
        breadcrumb: [
            {name:"Expenses", link:"/Expenses/Index"},
            {name: "Manage"}
        ] }         
    },
    {
        path: "/ExpenseApproval/index",
        name: "ExpenseApproval",
        component: () => import('../views/Expense/ExpenseApproval/ExpenseApprovalList.vue'),
        meta: { title: 'Expense Approval' ,
        breadcrumb: [
            {name:"Expenses", link:"/Expenses/Index"},
            {name: "Expense Approval"}
        ] } 
    },
    {
        path: "/ExpenseReport/Index",
        name: "ExpenseReport",          
        component: () => import('../views/Expense/ExpenseReport/ExpenseOverLimit.vue'),
        meta: { title: 'Expense Report',
        breadcrumb: [
            { name: "Expense Report" }
        ]}
    },
    {
        path: "/Expenses/Import",
        name: "ExpensesImport",
        component: () => import("../components/Common/ImportExpense.vue"),
        meta: { title: 'Import Expenses',
        breadcrumb: [
            { name: "Expenses" }
        ] } 
    },
    {
        path: "/ExpenseApproval/History",
        name: "ExpenseHistory",
        component: () => import('../views/Expense/ExpenseHistory/HistoryList.vue'),
        meta: { title: 'Expense History' ,
        breadcrumb: [
            {name:"Expenses Approval", link:"/ExpenseApproval/index"},
            {name: "Expense History"}
        ] }
    },
    {
        path: "/Expenses/Dashboard",
        name: "Dashboard",
        component: () => import('../views/Expense/Dashboard/Dashboard.vue'),
        meta: { title: 'Expenses Dashboard' ,
        breadcrumb: [
            {name:"Home", link:"/ExpenseApproval/index"},
            {name: "My Dashboard"}
        ] }
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback') {
        next()
    }
    else {
        if (to.path == '/logout') {
            authService.logOut();
        } else {
            const ustore = useloggedUserInfo();
            let app = ustore.GetUserInfo;
            if (app) {                
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = `${pathArray[1]}`;
                        actionName = `${pathArray[2]}`;
                    } else{
                        controllerName = `${pathArray[0]}`;
                        actionName = `${pathArray[1]}`;
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {                        
                        if (value.data)
                            next()
                        else                           
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            }
            else 
            {
                authService.authenticate(to.path).then(() => {
                    console.log('authenticating a protected url:' + to.path);
                    next();
                });
            }
        }
    }
});
export default router


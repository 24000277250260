<script>

    export default {
        props: {          
             fieldInfo: {
                type: Object,
                required: true
            }        
        },
        data: function () {
            return {
                value:''
            }
        },
        methods: {
            checkKey: function (e) {
                var vm = this;
                if (String.fromCharCode(e.which) == '-' || (!vm.isDecimal && String.fromCharCode(e.which) == '.') || 
                vm.value.length <= 0 && String.fromCharCode(e.which) == '.') {
                    e.preventDefault();
                    return false;
                }
                if (vm.isDecimal && vm.value.split('.')[1]) {
                    if (vm.value.split('.')[1].length >= vm.decimalPoints) {
                        e.preventDefault();
                        return false;
                    }
                }
                if((this.fieldInfo.name=='expected_response' || this.fieldInfo.name=='probability') && 
                this.fieldInfo.value && this.fieldInfo.value>1 && this.fieldInfo.value>100){
                    e.preventDefault();
                }           
            },           
            getRuleObj(){
               var initialRule={}
               initialRule.required=this.fieldInfo.is_required;
               initialRule.numeric=this.fieldInfo.dt_code=='decimal'?false: true;
               if(this.fieldInfo.dt_code=='decimal' && this.fieldInfo.regular_expression){
                initialRule.regex=this.fieldInfo.regular_expression;
               }
               if(this.fieldInfo.name=='expected_response' || this.fieldInfo.name=='probability'){
                initialRule.between=[1,100];
               }
               else if(this.fieldInfo.range_to){
                initialRule.between=[this.fieldInfo.range_from,this.fieldInfo.range_to];
               }
               else if(this.fieldInfo.length)
               {
                initialRule.max=[this.fieldInfo.length];
               }
               return initialRule;
            },
            onBlur(item){
                this.$emit('onBlur',item);
            },
            NumbersOnly(evt,fieldName) {
          var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && (fieldName=='probability' || fieldName=='expected_response')) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
        }
    }
</script>
<template>
     <Field :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" class="w-100"  :rules="getRuleObj()"  v-slot="{errors,touched,invalid}">
        <input  :type="fieldInfo.dt_code=='decimal'?'text':'number'" 
        v-bind:class="{'form-control w-100' : true,'is-invalid':touched && invalid}"  
        v-model="fieldInfo.value" :placeholder="GetFieldPlaceHolder(fieldInfo.name,fieldInfo.display_name)" v-on:keypress="NumbersOnly($event,fieldInfo.name)"
        @blur="onBlur(fieldInfo)"/>
        <span   :name="fieldInfo.name" class="invalid-feedback">{{errors[0]}}</span>
    </Field>
</template>
import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService { 
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  } 
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList" );
  }
  GetFullMenu(){
    return http.instanceCommon.get("/Common/GetFullMenu" );
  }
  GetFavDashboard() {
    return http.instanceCommon.get("/Common/GetFavDashboard");
  }
  ExpenseListing(params) {     ;   
    return  http.instance.get("/ExpenseListing/", { params });
  }
  ExpenseKanbanListing(params) {
    return  http.instance.get("/ExpenseKanbanListing/", { params });
  }
  GetDDLData(params) {
    return  http.instance.get("/Form/GetDDLData/", { params });
  }
  getDDLData(params) {
    return  http.instance.get("/Form/GetDDLData?" + params);
  }
  CommonDeleteData(params) {
    return http.instance.post("/Form/CommonDelete", { postString: params })
  }
  SendForApproval(params) {    
    return  http.instance.post("/SendForApproval?" + params );
  } 
  GetExpenseFileList(params) { 
    return  http.instance.get("/GetExpenseFileList?"+ params );
  }
  downloadAttachment(params) {  
    return  http.instance.get("/ExpenseDownloadFile?"+params,{ responseType: "blob" });
  }
  DeleteAttachment(params) {
    return http.instance.get("/FileDelete?"+params);
  }
  CommentsList(params){     
    return http.instance.get("/GetCommentsListingByExpenseId?"+params);
  }
  SaveComment(params) {    
    return http.instance.post("/SaveComment/",{postString:params});
  }
  ExpenseApprovalListing(params) { 
    return  http.instance.get("/ExpenseApprovalListing?"+ params );
  }
  async ExpenseGetProjectListing(params) {         
    return await http.instance.get("/GetProjectList/", { params });
  }
  ExpenseGetUserListing(params) {    
    return  http.instance.get("/GetUsersByCompanyId/", { params });
  }
  ExpenseApprovalHistory(params) {     
    return  http.instance.get("/ExpenseApprovalHistory?"+ params );
  }
  AddApproverStatusListing(params) { 
    return  http.instance.post("/AddApproverStatus?"+params );
  }
  ExpenseApproverStatusListing(params){    
    return  http.instance.get("/ListPriorPendingApprovalGroup?"+ params );
  }
  ImportExpensePost(params){
    return http.instance.post("/ImportExpensePost", params);
  }
  async UploadExpenseDetails(params){
    return await  http.instance.get("/UploadExpenseDetails?"+params);
  }
  GetTaskByProject(params){
    return http.instance.get("/GetTaskByProject?"+params);
  }
  GetExpenseLimitAvailableAmount(){
    return http.instance.get("/GetExpenseLimitAvailableAmount");
}
CheckFileSizeAndType(params){
  return http.instance.post("/CheckFileSizeAndType", params);
}
SaveExpenseBulk(params){
  return http.instance.post("/SaveExpenseBulk", params);
}
async GetProjectByCompanyIdAndUserId(){
  return await http.instance.get("/ProjectDropdownByCompanyIdAndUserId");
}
async GetAllCategoriesByCompanyId(){
  return await http.instance.get("/GetAllCategoriesByCompanyId");
}
async ReportMain(params){
  
  return await http.instanceCommon.post("Report/ReportMain",{postString:params});
}
GetStatusDDLData(params){
  return http.instanceCommon.get("/Common/GetStatusDDLData?"+params);
}
statusdropdown(params) {
  return http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
}
async GetStatusList(params) {  
  return await http.instance.get("/GetStatusList?" + params);
}
SaveUpdateTrip(params) {      
  return http.instance.post("/SaveUpdateTrip/",{postString:params});
}
SaveTag(params) {        
  return http.instance.post("/SaveTag", { postString: params })
}
async GetUsersByProjectIds(params) { 
  return await http.instance.get("/GetUsersByProjectIds?" + params);
}
async GettTripListingddl() { 
  return await http.instance.get("/GetTripName/");
}
async GettCategoryListingddl() {     
  return await http.instance.get("/GetCategoryName/");
}
async GetTaskListingddl(params) { 
  return await http.instance.get("/GetTaskByProject?" + params);
}
async GetCurrencyNameddl() {     
  return await http.instance.get("/GetCurrencyName/");
}
async GetTagNameddl(){  
  return await http.instance.get("/GetTagsByModuleId/");
}
SaveUpdateExpense(params){
  return http.instance.post("/ExpenseSaveUpdateExpense",params);
}
async GetExpenseByExpenseId(params) {       
  return await  http.instance.get("/GetExpenseByExpenseId?"+params);
}
GetBlobUrl(params){
  return http.instanceCommon.get("/Common/GetBlobUrl?"+params );
}
async GetCurrencyCode(params) {   
  return await http.instance.get("/GetCurrencyCode?" + params);
}
GetTaskByProject(params){    
  return http.instance.get("/GetTaskByProject?"+params);
}
async GetConfigurationValueForCompanyByKey(param){
  return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?"+param);
}
async GetHideContextMenuConfig(){
  return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
}
async SaveHideContextMenuConfig(param){
  return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?"+param);
}
async GetModuleMasterIdByModuleName(params){ 
  return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?"+params);
}
async GetConfigurationValue(params){   
  return await http.instance.get("/GetConfigurationValue?"+params);
}
async CheckPrivilege(params) {  
  return await http.instanceCommon.get("/Common/HasPermission?" + params);
}
async GetReports(params) { 
  return await http.instance.get("/GetReports?" + params);
}
async GetStartEndDate_New(param) {  
  return await http.instance.post("/GetStartEndDate_New?" + param);
}
async widgetSave(params) {  
  return await http.instance.post("/ReportsSave?" + params);
}
async GetDashboardData(params) {
  return await http.instance.get(
    "/AllReportTypeExpenseByReportTypeParamNew?" + params
  );
}
async ExpenseDetail(params) {
  return await http.instance.get("/ExpenseDetail?" + params);
}
async gettoDoList(params){    
  return  await http.instance.get("/gettoDoList?"+params);
}
async DashBoardMinWidgetData(param){ 
  return await http.instanceProject.get("/Project/DashBoardMinWidgetData?"+param);
}
async ToDoListDashboard(param){  
  return await http.instanceProject.get("/Project/ToDoListDashboard?"+param);
}
async ExpenseDetailByDate(param){  
  return await http.instance.get("/ExpenseDetailByDate?"+param);
}
async ReportsRemove(param){
  return await http.instance.get("/ReportsRemove?"+param);
}
async Delete(params) {  
  return http.instanceCommon.get("/Common/Delete?"+params);
}
async GetDynamicForm(params) {
  return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
}
async SaveDynamicForm(params) {
  return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
}
async CheckAmountDateRange(params) {  
  return http.instance.post("/CheckAmountDateRange", { postString: params })
}

}
export default new DataService();
